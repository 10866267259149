$(function () {
	$(document.body).on(
    'click',
    '.card-body-users [data-action], .card-body-countries [data-action], .event-review-index [data-action], .item-index [data-action], .card-body-tags [data-action]',
    function () {
      var id = $(this).closest('tr').data('key'),
        ajaxUrl = $(this).data('url'),
        action = $(this).data('action'),
        value = $(this).data('value'),
        pjaxContainer = $(this).closest('[data-pjax-container]');

      $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: { id: id, action: action, value: value },
        dataType: 'json',
        success: function (response) {
          if (response) {
            $.pjax.defaults.timeout = false;
            $.pjax.reload({ container: '#' + pjaxContainer.prop('id') });
          }
        },
        error: function (errormessage) {
          alert('not working');
        },
      });
    }
  );
});
